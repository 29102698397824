
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';

import { getAllProvincesData } from '@/services/my-account';
import {
  BRAND_NAME_EN,
  BRAND_NAME_FR,
  DEFAULT_SITE_LINK,
  SITE_LINK,
} from '@/utils/constants';

const MyAccountModal = (props: any) => {
  const { locale }: any = useRouter();
  const [storeList, setStoreList] = useState([]);
  const [siteLink, setSiteLink] = useState(DEFAULT_SITE_LINK);
  const [provinceList, setProvinceList] = useState([]);
  const [facilityList, setProvinceFacility] = useState([]);

  const { onClose, open } = props;
  const [province, setProvince] = useState('Alberta');
  const [store, setStore] = useState('Edmonton Coronation');

  const filterFacility = (data: any, facilitySlug: any) => {
    const filterFac = data.find(
      (element: any) => element?.attributes?.Name === facilitySlug
    )?.attributes?.Facilities?.data;
    return filterFac;
  };

  const getProvinceData = async () => {
    let brand = BRAND_NAME_EN;
    if (locale === 'fr') brand = BRAND_NAME_FR;
    const provinceListD = await getAllProvincesData(locale, brand);
    const provinceData = (await provinceListD?.data?.data) || null;
    setProvinceFacility(provinceData);
    filterFacility(provinceData, 'Alberta');
    setStoreList(filterFacility(provinceData, 'Alberta'));
    const prov: any = [];
    provinceData.forEach((element: any) => {
      prov.push(element?.attributes?.Name);
    });
    setProvinceList(prov);
    const provS: any = [];
    provinceData.forEach((element: any) => {
      provS.push(element?.attributes?.Slug);
    });
    setStore(prov[0]);
  };

  const handleStore = async (e: any) => {
    setStoreList([]);
    if (e && e.target) setProvince(e?.target?.value);
    const currentprovince = e?.target?.value;
    setStoreList(filterFacility(facilityList, currentprovince));
  };

  useEffect(() => {
    getProvinceData();
    handleStore(undefined);
  }, []);

  useEffect(() => {
    if (storeList?.length)
      setSiteLink(
        storeList[0]?.attributes?.Sitelink_URL_ID || DEFAULT_SITE_LINK
      );
  }, [storeList]);

  const handleCloseClick = () => onClose();
  const getSiteLink = (e: any, data: any) => {
    if (e && e.target) {
      const item = data.filter(
        (val: any) =>
          `${val?.attributes?.Title} - ${val?.attributes?.Address?.Street_1}` ===
          e.target.value
      );
      setSiteLink(item[0]?.attributes?.Sitelink_URL_ID || DEFAULT_SITE_LINK);
    }
  };

  const handleSubmit = () => {
    window.open(
      `${SITE_LINK}sCorpCode=ilv2Y31A9yHEzplusa5/gLLlA==&sLocationCode=${siteLink}==&1=1`,
      '_blank'
    );
  };

  return (
    <Modal
    show={open}
    animation={false}
    id="myAccountModal"
    onHide={handleCloseClick}
  >
    <div className="modal-header">
      <h5 className="modal-title">
        <FormattedMessage id="my-account-heading" />
      </h5>
      <button
        type="button"
        className="border-0 bg-transparent text-white"
        onClick={handleCloseClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>
      </button>
    </div>
    <Modal.Body>
      {' '}
      <div className="modal-body">
        <p>
          <FormattedMessage id="my-account-subheading" />.
        </p>
        <div className="fieldSet provinceField">
          <label className="form-label" key="provinceSelect">
            <FormattedMessage id="my-account-province" />:
          </label>
          <select
            className="form-select provinceSelect"
            onChange={handleStore}
            defaultValue={province}
          >
            {provinceList.length
              ? provinceList.map((item) => (
                  <option key={item} defaultValue={item}>
                    {item}
                  </option>
                ))
              : ''}
          </select>
        </div>
        <div className="fieldSet facilityField">
          <label className="form-label" key="storeSelect">
            <FormattedMessage id="my-account-store" />:
          </label>
          <select
            className="form-select storeSelect"
            aria-label=" Select Store"
            onChange={(e) => getSiteLink(e, storeList)}
            defaultValue={store}
          >
            {storeList?.length
              ? storeList.map((item) => (
                  <option key={item?.attributes?.Title} defaultValue={item}>
                    {item?.attributes?.Title} -{' '}
                    {item?.attributes?.Address?.Street_1}
                  </option>
                ))
              : ''}
          </select>
        </div>
        <Button
          className="btn as-primary-btn verify w-100 mt-3"
          onClick={handleSubmit}
        >
          <FormattedMessage id="my-account-button" />
        </Button>
      </div>
    </Modal.Body>
  </Modal>
  );
};

export default MyAccountModal;
